
import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { doc, getDoc } from 'firebase/firestore';
import { textdb ,auth} from '../../firebaseConfig';
import DOMPurify from 'dompurify';
import "./ServiceDetails.css"
import { collection, } from 'firebase/firestore';
import { useCollection } from 'react-firebase-hooks/firestore';

import { FaLocationDot ,FaGreaterThan } from "react-icons/fa6";
import { CiCalendarDate } from "react-icons/ci";
import { Helmet } from 'react-helmet';
import { FaArrowRight } from "react-icons/fa";
import Load from '../Loder/Load';
export const ServiceDetails = (props) => {
  const [Services, loading, error] = useCollection(collection(textdb, 'Services'));

  const { ServiceId } = useParams();
  const history = useNavigate();
  const currentUser = auth.currentUser;

  const [Service, setService] = useState(null);
  const truncateText = (text, maxLength) => {
    const words = text.split(' ');
    if (words.length > maxLength) {
      return words.slice(0, maxLength).join(' ') + '...';
    }
    return text;
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []); 
  useEffect(() => {
    const fetchServiceDetails = async () => {
      try {
        const docRef = doc(textdb, 'Services', ServiceId);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          setService(docSnap.data());
        } else {
          console.log('No such document!');
        }
      } catch (error) {
        console.error('Error fetching document: ', error);
      }
    };

    fetchServiceDetails();
  }, [ServiceId]);

  if (!Service) {
    return <Load/>;
  }


  const handleGetQuote = (title) => {
    if (currentUser) {
      history(`/cuchat/${currentUser.uid}?title=${encodeURIComponent(title)}`);
    } else {
      history('/login');
    }
  };



  return (
    <div className='main-detail'>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{Service.title}</title>
        <meta name="description" content="" />
        {/* <link rel="canonical" href="http://mysite.com/example" /> */}
      </Helmet>
      <div className='inst-detail-hero'>
        <div className='top-txt'>
          <Link to="/" style={{ textDecoration: "none" }}><li className='inst-title-top'>Home</li></Link><span ><FaGreaterThan /></span>
          <Link to="/Service" style={{ textDecoration: "none" }}><li className='inst-title-top'>Service</li></Link><span ><FaGreaterThan /></span>
          <li className='inst-title-top1'>{Service ? Service.title : 'Loading...'}</li>
        </div>

        <div  className='main-Service-detail-title-hero'>
          <h1 className='main-Service-detail-title-hero-h1'>{Service ? Service.title : 'Loading...'} </h1>
          {/* <p className='details-Service-time'> <span className='detail-Service-icon'><CiCalendarDate /></span>
                  {Service.timestamp &&
                    new Date(Service.timestamp.toDate()).toLocaleString('en-US', {
                      month: 'long',
                      day: 'numeric',
                      year: 'numeric',

                    })}
                </p> */}
                
                  <div className='book-btn-container'>
                  {currentUser ? (
  <Link to={`/cuchat/${currentUser.uid}?title=${encodeURIComponent(`i need ${Service.title}`)}`} style={{ textDecoration: 'none' }} className='Service-link hidden'>
    <button onClick={() => handleGetQuote(Service.title)} className='btn-book'>Send Requirements → </button>
  </Link>
) : (
  <button onClick={handleGetQuote} className='btn-book'>Login to Send Requirements</button>
)}
                    </div>
        </div>
        


      </div>
      <div className='Service-details-sub'>
      <div data-aos='fade-up' className='detail'>
        <img loading='lazy'  src={Service.imageUrl} alt={Service.title} style={{ maxWidth: '95%',borderRadius:'10px',marginLeft:'10px' }} />
        <div className='data-text-container'>
        <h2>{Service.title}</h2>
        <p>{Service.p1} </p>
       


        <div className='Service-quill' dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(Service.content) }}></div>

        </div>
      </div>
      <div className='Service-short-data'>
      {currentUser ? (
  <Link to={`/cuchat/${currentUser.uid}?title=${encodeURIComponent(`i need ${Service.title}`)}`} style={{ textDecoration: 'none' }} className='Service-link hidden'>
    <button onClick={() => handleGetQuote(Service.title)} className='btn-book'>Send Requirements → </button>
  </Link>
) : (
  <button onClick={handleGetQuote} className='btn-book'>Login to Send Requirements</button>
)}




       <h2>Other Service</h2>
      
  
<div>
{loading && <p>loding....</p>}
      {error && <p>Error fetching Services</p>}

      {Services && (
        <div className='details-d1-Service'>
          {Services.docs.map((Service) => {
            const data = Service.data();
            return (
              <Link style={{textDecoration:"none"}} to={`/service/${Service.id}`} className='Service-link'>
              <div data-aos='fade-up' className='details-Service-card' key={Service.id}>
                <img className='d-Service-card-image' src={data.imageUrl} alt={data.title} style={{ maxWidth: '100%' }} />
                <div className='details-Service-data'>
                
                 
               
                <h3 className='details-Service-title'>{data.title}</h3>
                <p className='details-Service-time'> <span className='d-Service-icon'><CiCalendarDate /></span>
                  {data.timestamp &&
                    new Date(data.timestamp.toDate()).toLocaleString('en-US', {
                      month: 'long',
                      day: 'numeric',
                      year: 'numeric',

                    })}
                </p>
                

                
               
              </div>       
              
                     </div>  </Link>

            );
          })}
        </div>
      )}
</div>
    
       </div>
      </div>
    </div>
  );
};


