// Footer.jsx

import React from 'react';
import { FaPhone, FaEnvelope, FaFacebook, FaTwitter, FaLinkedin } from 'react-icons/fa';
import './Footer.css';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <div className='main-footer'>
    <footer className='footer-container'>
      <div className='footer-logo'>
        <img src='https://res.cloudinary.com/ddkyeuhk8/image/upload/v1710527710/j58dvyugfjtpxd3ywjes.png' alt='Company Logo' />
        <p></p>
      </div>

      <div className='footer-links'>
        <h3>Helpful Links</h3>
        <ul>
          <li><a href='/'>Home</a></li>
          <li><a href='/about'>About Us</a></li>
          <li><a href='/services'>Services</a></li>
          <li><a href='/contact'>Contact</a></li>
          <li><a href='/privacy-policy'>Privacy policy and T&C </a></li>

        </ul>
      </div>

      {/* <div className='footer-services'>
        <h3>Services</h3>
        <ul>
          <li><a href='/service1'>Service 1</a></li>
          <li><a href='/service2'>Service 2</a></li>
          <li><a href='/service3'>Service 3</a></li>
        </ul>
      </div> */}

      <div className='footer-contact'>
        <h3>Contact Us</h3>
        <p>11th Floor, The Citadel, opp Star Bazaar, Adajan Gam, Surat 395009</p>
        <p> <strong>Email:</strong> Info@secondpundit.com</p>
        <p><strong>Phone:</strong> +91 77780 70439</p>
      </div>

      <div className='footer-social'>
        <h3>Connect With Us</h3>
        <ul>
          <li><a href='https://facebook.com' target='_blank' rel='noopener noreferrer'><FaFacebook /></a></li>
          <li><a href='https://twitter.com' target='_blank' rel='noopener noreferrer'><FaTwitter /></a></li>
          <li><a href='https://linkedin.com' target='_blank' rel='noopener noreferrer'><FaLinkedin /></a></li>
        </ul>
      </div>
      

    </footer>
   <span  className='desginedby'> <p1> &copy; Copyright 2024 GENTLEFOLK CONSULTING PVT LTD <Link
  to="https://www.anantainfotech.com"
  target="_blank"
  rel="noopener noreferrer"
><b>All rights reserved.</b> </Link> </p1>  </span>

    </div>
  );
};

export default Footer;
