import React from 'react'
import "./Welcome.css"
const Welcome = () => {
  return (
    <div className='main-wel'>

        <div>
            <span> <h3>Welcome </h3> </span>
            <span> <h2>Our 10 years working experience to <span style={{fontWeight:"400"}}>take care of <br/> your business goal. </span></h2> </span>

            <span className='wlcome-img'> 

                <img src='https://res.cloudinary.com/ddkyeuhk8/image/upload/v1720151179/b0833011-4d82-4ac1-b09d-a704db9239fc.png' alt='img' />
            </span>
            <span> <p>SecondPundit, where expertise
meets collaboration to drive your business
forward. We specialize in providing tailored
consulting solutions to SMEs, offering a
comprehensive range of services to support
your journey towards success.</p></span>


       </div>
    </div>
  )
}

export default Welcome