// src/BusinessConsultancy.js
import React from 'react';
import './BusinessConsultancy.css';

const BusinessConsultancy = () => {
  return (
    <div className="business-consultancy-container">
      <div className="image-section">
        <img src="https://res.cloudinary.com/ddkyeuhk8/image/upload/v1720531142/35f6283b-5373-4c13-a007-fbe3d555c35d.png" alt="Business Consultancy" className="consultancy-image" />
      </div>
      <div className="content-section">
        <h1><span className="content-section-highlight">Business Consultancy</span> for Your Business Growth.</h1>
        <p>
        At Second Pundit, we specialize in driving business growth through strategic consultancy services. Our expert team is dedicated to helping you navigate the complexities of the modern market, optimize operations, and unlock your business's full potential. Whether you're a startup looking for guidance or an established company aiming to scale, Second Pundit offers tailored solutions to meet your unique needs. Partner with us to achieve sustainable growth, improve efficiency, and stay ahead of the competition.
        </p>
        <div className="skill">
          <svg className="circular-progress" viewBox="0 0 36 36">
            <path
              className="circle-bg"
              d="M18 2.0845
                 a 15.9155 15.9155 0 0 1 0 31.831
                 a 15.9155 15.9155 0 0 1 0 -31.831"
            />
            <path
              className="circle"
              strokeDasharray="90, 100"
              d="M18 2.0845
                 a 15.9155 15.9155 0 0 1 0 31.831
                 a 15.9155 15.9155 0 0 1 0 -31.831"
            />
            <text x="18" y="20.35" className="percentage-text">95%</text>
          </svg>
          <span>Consultancy Agency Skill</span>
        </div>
      </div>
      
    </div>
  );
};

export default BusinessConsultancy;
