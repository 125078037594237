import React from 'react'
import Hero from '../../Components/Hero/Hero'
import { ServiceDisplay } from '../../Components/Services/ServiceDisplay'
import Step from '../../Components/HomeComponents/Step'
import { Dservice } from '../../Components/Services/Dservice'
import { Helmet } from 'react-helmet'
import { Count } from '../../Components/HomeComponents/Count/Count'
import "./Home.css"
import { Aboutus } from '../About/About'
import TechPartner from '../../Components/HomeComponents/TechnologyPartner/TechPartner'
import Welcome from '../../Components/HomeComponents/Welcome/Welcome'
import BusinessConsultancy from '../../Components/HomeComponents/BusinessConsultancy/BusinessConsultancy'
import WhyUs from '../../Components/HomeComponents/WhyUs/WhyUs'

export const Home = () => {
  return (
    <div>
         <Helmet>
        <meta charSet="utf-8" />
        <title>Home</title>
        <meta name="description" content="Welcome to SecondPundit, where expertise meets collaboration to drive your business forward." />
        {/* <link rel="canonical" href="http://mysite.com/example" /> */}
      </Helmet>
        <Hero/>
        <Welcome/>
        <Count/>
        <div>
          <Dservice/>
        </div>
        <div style={{height:"120%"}}>
        <Step/>
        </div>
        <div className='buswhy'>
       <BusinessConsultancy/>
          <WhyUs/>
        </div>
          {/* <TechPartner/> */}
        </div>
  )
}
