import React, { useEffect, useState } from 'react';
import './Count.css';

export const Count = () => {
  const [counts, setCounts] = useState({
    clients: 0,
    industryServed: 0,
    startups: 0,
    yearsExperience: 0,
  });

  useEffect(() => {
    const targets = {
      clients: 12,
      industryServed: 8,
      startups: 1,
      yearsExperience: 30 ,
    };

    const duration = 2000;

    const steps = {
      clients: Math.ceil(targets.clients / (duration / 10)),
      industryServed: Math.ceil(targets.industryServed / (duration / 10)),
      startups: Math.ceil(targets.startups / (duration / 10)),
      yearsExperience: Math.ceil(targets.yearsExperience / (duration / 10)),
    };

    const intervals = Object.keys(targets).map((key) =>
      setInterval(() => {
        setCounts((prevCounts) => {
          const nextCount = prevCounts[key] + steps[key];
          return {
            ...prevCounts,
            [key]: nextCount >= targets[key] ? targets[key] : nextCount,
          };
        });
      }, 10)
    );

    return () => {
      intervals.forEach(clearInterval);
    };
  }, []);

  return (
    <div className="count-container">
      <div   className="count-card">
        <div style={{background:"#F0F3FD"}} className="count-detail">
          <span className='count-details-idata'>
          <img style={{width:"70%"}} src="https://res.cloudinary.com/ddkyeuhk8/image/upload/v1720500232/f6d93631-bf6e-46b9-b5b4-fbc46b7952b6.png" alt="Clients" />
          </span>
          <span className='count-data'>
          <h1>{counts.clients.toLocaleString()}+</h1>
          <p>Clients</p>
          </span>
        </div>
        <div  style={{background:"#F1FBFD"}} className="count-detail">
          <span className='count-details-idata'>
          <img style={{width:"70%"}} src="https://res.cloudinary.com/ddkyeuhk8/image/upload/v1720500287/6a9836c0-fad7-43be-b003-f2c033dd3bab.png" alt="Clients" />
          </span>
          <span className='count-data'>
          <h1>{counts.yearsExperience.toLocaleString()}+</h1>
          <p>Professionals
</p>
          </span>
        </div>
        <div  style={{background:"#FFF3FC"}} className="count-detail">
          <span className='count-details-idata'>
          <img style={{width:"70%"}} src="https://res.cloudinary.com/ddkyeuhk8/image/upload/v1720500270/f8841268-fd7f-47c1-ac89-88cfb2826198.png" alt="Clients" />
          </span>
          <span className='count-data'>
          <h1>{counts.industryServed.toLocaleString()}+</h1>
          <p>Industries <br/>working in</p>
          </span>
        </div>
        <div style={{background:"#F3FFF1"}} className="count-detail">
          <span className='count-details-idata'>
          <img style={{width:"70%"}} src="https://res.cloudinary.com/ddkyeuhk8/image/upload/v1720500275/6c530c44-985f-454c-a7e1-436deaa6c19a.png" alt="Clients" />
          </span>
          <span className='count-data'>
          <h1>{counts.startups.toLocaleString()}+</h1>
          <p> Startup get funded 
          </p>
          </span>
        </div>
      </div>
    </div>
  );
};


