import React, { useState, useEffect } from 'react';
import { useCollection } from 'react-firebase-hooks/firestore';
import { storage, textdb } from '../../firebaseConfig';
import { doc, setDoc, collection, query, where, deleteDoc } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL, deleteObject } from 'firebase/storage';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "./Portfolio.css";

const services = [
  'Legal Services',
  'Chartered Accountancy Services',
  'Professional Services',
  'Marketing & Advertising Solutions',
  'IT Development Services',
  'Sales Management Services',
  'Printing Solutions',
  'Management Consultancy Services'
];

export const Portfolio = () => {
  const [title, setTitle] = useState('');
  const [p1, setP1] = useState('');
  const [Link, setLink] = useState('');

  const [image, setImage] = useState(null);
  const [selectedService, setSelectedService] = useState(services[0]); // Default to the first service

  const portfolioQuery = query(collection(textdb, 'portfolio'), where('service', '==', selectedService));
  const [portfolio, loading, error] = useCollection(portfolioQuery);

  const handleImageChange = (e) => {
    if (e.target.files.length > 0) {
      setImage(e.target.files[0]);
    }
  };

  const handleUpload = async () => {
    if (!title || !p1 || !image ||  !selectedService) {
      toast.error('Please fill in all fields');
      return;
    }

    toast.info('Uploading...', { autoClose: 3000 });

    try {
      const imageRef = ref(storage, `portfolio_images/${image.name}`);
      await uploadBytes(imageRef, image);
      const imageUrl = await getDownloadURL(imageRef);

      const newPortfolioRef = doc(collection(textdb, 'portfolio'));

      await setDoc(newPortfolioRef, {
        title,
        p1,
        Link,

        imageUrl,
        service: selectedService,
        timestamp: new Date(),
      });

      toast.success('Portfolio uploaded successfully!');
      setTitle('');
      setP1('');
      setLink('');

      setImage(null);
    } catch (error) {
      console.error('Error uploading portfolio:', error);
      toast.error('Error uploading portfolio');
    }
  };

  const handleDelete = async (portfolioId, imageUrl) => {
    try {
      const portfolioRef = doc(collection(textdb, 'portfolio'), portfolioId);

      await deleteDoc(portfolioRef);

      const imageRef = ref(storage, imageUrl);
      await deleteObject(imageRef);

      toast.success('Portfolio deleted successfully!');
    } catch (error) {
      console.error('Error deleting portfolio:', error);
      toast.error('Error deleting portfolio');
    }
  };

  return (
    <div className='mainup'>
      <div className='tabs'>
        {services.map((service) => (
          <button
            key={service}
            className={selectedService === service ? 'active' : ''}
            onClick={() => setSelectedService(service)}
          >
            {service}
          </button>
        ))}
      </div>
      
      <div className='Mainuploadportfolio'>
        <div className='uploadportfolio'>
          <h2>Upload Portfolio for {selectedService}</h2>
          <div className='uploadportfolio'>
            <label>Title:</label>
            <input type='text' value={title} onChange={(e) => setTitle(e.target.value)} />
          </div>
          <div className='uploadportfolio'>
            <label>Description:</label>
            <input type='text' value={p1} onChange={(e) => setP1(e.target.value)} />
          </div>
          <div className='uploadportfolio'>
            <label>Link</label>
            <input type='text' value={Link} onChange={(e) => setLink(e.target.value)} />
          </div>
          <div className='uploadportfolio'>
            <label>Image:</label>
            <input type='file' onChange={handleImageChange} />
          </div>
          <button className='uplodbtn' onClick={handleUpload}>Upload</button>
        </div>

        <h2>{selectedService} Portfolio List</h2>
        {loading && <p>Loading...</p>}
        {error && <p>Error fetching portfolio</p>}
        {portfolio && (
          <div className='portfolio-main'>
            {portfolio.docs.map((portfolioDoc) => {
              const data = portfolioDoc.data();
              return (
                <div className='portfolio-Card' key={portfolioDoc.id}>
                  <img src={data.imageUrl} alt={data.title} />
                  <p className='time'>{data.timestamp && data.timestamp.toDate().toLocaleString()}</p>
                  <h3 className='btitle'>{data.title}</h3>
                  <p className='portfoliop'>{data.p1}</p>
                  <p className='portfoliop'>{data.Link}</p>

                  <button className='delete' onClick={() => handleDelete(portfolioDoc.id, data.imageUrl)}>Delete</button>
                </div>
              );
            })}
          </div>
        )}
        <ToastContainer />
      </div>
    </div>
  );
};
