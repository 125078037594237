// WhyUs.js
import React from 'react';
import './WhyUs.css';
import { Link } from 'react-router-dom';

const WhyUs = () => {
  return (
    <div className="why-us-container">
      <h2 className="title">WHY US</h2>
      <h3 className="subtitle"> <span className='why-highlight'>The reasons to choose us</span> as  your <br/> business partner</h3>
      <div className="card-container">
        <div className="card card-experience">
          <img src='https://res.cloudinary.com/ddkyeuhk8/image/upload/v1720543184/e8de4679-7c0a-4370-9d1e-5aa25c4e4027.png' alt="Experience" className="card-image"/>
          <div className="card-content">
            <h4>Highly Experienced</h4>
            <p>Second Pundit excels in driving business growth efficiently.</p>
          </div>
        </div>
        <div className="card card-services">
          <img src='https://res.cloudinary.com/ddkyeuhk8/image/upload/v1720543217/59e146a6-c05f-46b9-9d4b-7a3e5c4e2471.png' alt="Services" className="card-image"/>
          <div className="card-content">
            <h4>Bunch of Services</h4>
            <p>Second Pundit provides diverse solutions for comprehensive business growth.</p>
          </div>
        </div>
        <div className="card card-support">
          <img src='https://res.cloudinary.com/ddkyeuhk8/image/upload/v1720543230/081db248-ade6-4573-803a-a418fd9cfbaf.png' alt="Support" className="card-image"/>
          <div className="card-content">
            <h4>Quality Support</h4>
            <p>Second Pundit ensures top-notch assistance for your business success.</p>
          </div>
        </div>
      </div>
      <Link to="/service">
      <button className="all-services-button">All Services</button></Link>
    </div>
  );
};

export default WhyUs;
