import React, { useState, useEffect } from 'react';
import { useCollection } from 'react-firebase-hooks/firestore';
import { textdb,auth } from '../../firebaseConfig';
import { collection } from 'firebase/firestore';
import './DisplayService.css';
import { Link, useNavigate } from 'react-router-dom';
import { CiCalendarDate } from "react-icons/ci";
import { Helmet } from 'react-helmet';
import { FaArrowRight } from "react-icons/fa";
import Load from '../Loder/Load';
export const ServiceDisplay = () => {
  const history = useNavigate();
  const currentUser = auth.currentUser;




  useEffect(() => {
    window.scrollTo(0, 0);
  }, []); 
  const [Services, loading, error] = useCollection(collection(textdb, 'Services'));
  const navigate = useNavigate();
  const truncateText = (text, maxLength) => {
    const words = text.split(' ');
    if (words.length > maxLength) {
      return words.slice(0, maxLength).join(' ') + '...';
    }
    return text;
  };
  return (
    <div className='main-d-Service'>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Service</title>
        <meta name="description" content=" Read Our Services" />
        {/* <link rel="canonical" href="http://mysite.com/example" /> */}
      </Helmet>
      <div className='service89-hero'> 
      
      <h1 className="title-service1"> <span className='title-service1-color'>Bunch of Services</span> to Rock Your Business</h1>
      <span className='contact-navigate'>  <p>Home</p> <p>> </p><p className='contact-p'>Service</p></span>
      
      </div>
      {loading && <Load />}
      {error && <p>Error fetching Services</p>}
      {Services && (
        <div className='service-section-new'>
          {Services.docs.map((Service) => {
            const data = Service.data();
            return (
              <Link key={Service.id} to={`/service/${Service.id}`} className='service-link'>
                <div className='service-section-card'>
                  <img loading="lazy" src={data.iconImageUrl} alt={data.title} className='service-image'/>
                  <div className='service-content'>
                    <h3 className='section-service-title'>{data.title}</h3>
                    <p className='section-service-p'>{truncateText(data.p1, 10)}</p>
                  </div>
                </div>
              </Link>
            );
          })}
</div>

      )}
    </div>
  );
};


