import React, { useState } from 'react';
import { createUserWithEmailAndPassword, updateProfile, sendEmailVerification } from 'firebase/auth';
import { auth, textdb } from '../../firebaseConfig';
import { collection, doc, setDoc } from 'firebase/firestore';
import { ToastContainer, toast } from 'react-toastify';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import 'react-toastify/dist/ReactToastify.css';
import './Register.css'; // Import your CSS file

const Register = () => {
    const [values, setValues] = useState({
        name: '',
        email: '',
        mobile: '',
        password: '',
        confirmPassword: ''
    });

    const [passwordVisible, setPasswordVisible] = useState(false);
    const [submitButtonDisabled, setSubmitButtonDisabled] = useState(false);

    const handlePasswordVisibility = () => {
        setPasswordVisible(!passwordVisible);
    };

    const handleSubmission = async () => {
        if (values.password !== values.confirmPassword) {
            toast.error('Passwords do not match!');
            return;
        }

        setSubmitButtonDisabled(true);

        try {
            const userCredential = await createUserWithEmailAndPassword(auth, values.email, values.password);
            const user = userCredential.user;

            await sendEmailVerification(user);

            await updateProfile(user, {
                displayName: values.name,
            });

            const usersCollection = collection(textdb, 'users');
            const newUserDoc = doc(usersCollection, user.uid);

            await setDoc(newUserDoc, {
                name: values.name,
                email: values.email,
                role: 'user',
                joinTimestamp: new Date().getTime(),
            });

            setSubmitButtonDisabled(false);
            toast.success('Registration successful! Please verify your email.');
        } catch (err) {
            console.error(err.code, err.message);
            setSubmitButtonDisabled(false);
            toast.error(err.message);
        }
    };

    return (
        <div className="auth-container">
            <h2 className="auth-header">Register</h2>
            <div className="input-group">
                <label className="label">Full Name:</label>
                <input
                    type="text"
                    className="input-field"
                    value={values.name}
                    onChange={(e) => setValues({ ...values, name: e.target.value })}
                />
            </div>
            <div className="input-group">
                <label className="label">Email:</label>
                <input
                    type="email"
                    className="input-field-email"
                    value={values.email}
                    onChange={(e) => setValues({ ...values, email: e.target.value })}
                />
            </div>
            <div className="input-group">
                <label className="label">Mobile:</label>
                <input
                    type="text"
                    className="input-field"
                    value={values.mobile}
                    onChange={(e) => setValues({ ...values, mobile: e.target.value })}
                />
            </div>
            <div className="input-group">
                <label className="label">Password:</label>
                <div className="password-input">
                    <input
                        type={passwordVisible ? "text" : "password"}
                        className="input-field"
                        value={values.password}
                        onChange={(e) => setValues({ ...values, password: e.target.value })}
                    />
                    <button type="button" className="password-toggle" onClick={handlePasswordVisibility}>
                        {passwordVisible ? <FaEyeSlash /> : <FaEye />}
                    </button>
                </div>
            </div>
            <div className="input-group">
                <label className="label">Confirm Password:</label>
                <div className="password-input">
                    <input
                        type={passwordVisible ? "text" : "password"}
                        className="input-field"
                        value={values.confirmPassword}
                        onChange={(e) => setValues({ ...values, confirmPassword: e.target.value })}
                    />
                    <button type="button" className="password-toggle" onClick={handlePasswordVisibility}>
                        {passwordVisible ? <FaEyeSlash /> : <FaEye />}
                    </button>
                </div>
            </div>
            <button className="auth-button" onClick={handleSubmission} disabled={submitButtonDisabled}>
                {submitButtonDisabled ? 'Loading...' : 'Register'}
            </button>
            <ToastContainer />
        </div>
    );
};

export default Register;
