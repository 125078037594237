import React from 'react'
import './Tandc.css'
const Tandc = () => {
  return (
    <div className='mainterm-container'>
<div className='term-hero'>
      <h1 className="terms-title">Privacy Policy</h1>
      <p>Date of Last Revision: 10 August 2024</p>
      </div>
<div className="terms-container">
    
      <section className="terms-section">
        <h2 className="section-title">Refund Policy</h2>
        <ul className="terms-list">
          <li><strong>Time frame:</strong> 7-14 days from service delivery.</li>
          <li><strong>Refund policy:</strong> If a client is not satisfied with the service, they can request a refund within 7-14 days from service delivery. Refunds will be processed after deducting a 10% administration fee.</li>
          <li><strong>Refund process:</strong> Clients must submit a refund request in writing to <a href="mailto:info@secondpundit.com">info@secondpundit.com</a>, including reasons for dissatisfaction.</li>
          <li><strong>Refund timeline:</strong> Refunds will be processed within 7-10 business days of receiving the refund request.</li>
        </ul>
      </section>

      <section className="terms-section">
        <h2 className="section-title">Privacy Policy</h2>
        <p className="terms-paragraph">We collect personal and business information from clients and professionals, including:</p>
        <ul className="terms-list">
          <li>Name</li>
          <li>Email</li>
          <li>Phone number</li>
          <li>Payment details</li>
          <li>Business information (company name, address, etc.)</li>
        </ul>
        <p className="terms-paragraph">Information is used for:</p>
        <ul className="terms-list">
          <li>Service delivery</li>
          <li>Communication</li>
          <li>Marketing purposes</li>
        </ul>
        <p className="terms-paragraph">Data is stored securely using:</p>
        <ul className="terms-list">
          <li>Encryption</li>
          <li>Secure servers</li>
          <li>Access controls</li>
        </ul>
        <p className="terms-paragraph">Data sharing: We do not share client or professional data with third parties, except for payment processing.</p>
        <p className="terms-paragraph">Cookie policy: We use cookies to improve user experience and track website interactions.</p>
      </section>

      <section className="terms-section">
        <h2 className="section-title">Terms and Conditions</h2>
        <ul className="terms-list">
          <li>Users must be 21+ years old and authorized to represent their business.</li>
          <li>We are liable for service quality and delivery, ensuring professionals meet our quality standards.</li>
          <li><strong>Cancellation policy:</strong> Clients must inform us 48 hours before service cancellation. Professionals must discuss with clients before cancellation.</li>
          <li><strong>Payment terms:</strong> Clients must pay within 7 days of service delivery. Professionals will be paid within 14 days of client payment.</li>
          <li><strong>Service delivery:</strong> Services will be delivered as agreed upon in the service agreement. Professionals will maintain confidentiality and data protection.</li>
        </ul>
      </section>

      <section className="terms-section">
        <h2 className="section-title">Pricing</h2>
        <ul className="terms-list">
          <li>Customized pricing based on service requirements and professional expertise.</li>
          <li>Pricing will be quoted by professionals and approved by clients before service commencement.</li>
          <li>Payment security: We use secure payment gateways and ensure timely payment to professionals.</li>
        </ul>
      </section>

      <section className="terms-section">
        <h2 className="section-title">Additional Policies</h2>
        <ul className="terms-list">
          <li><strong>Service Quality Guarantee:</strong> We ensure quality services from professionals; if unsatisfied, clients can request a rework or refund.</li>
          <li><strong>Professional Verification:</strong> We verify professionals' credentials and expertise before onboarding.</li>
          <li><strong>Client Protection:</strong> We protect client information and ensure secure payment processing.</li>
          <li><strong>Dispute Resolution:</strong> Any disputes will be resolved through mediation and arbitration.</li>
        </ul>
      </section>
    </div>

    </div>
  )
}

export default Tandc