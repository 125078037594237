import React, { useEffect } from 'react';
import { useCollection } from 'react-firebase-hooks/firestore';
import { textdb, auth } from '../../firebaseConfig';
import { collection } from 'firebase/firestore';
import './DisplayService.css';
import { Link, useNavigate } from 'react-router-dom';
import Load from '../Loder/Load';

export const Dservice = () => {
  const navigate = useNavigate();
  const currentUser = auth.currentUser;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []); 

  const [Services, loading, error] = useCollection(collection(textdb, 'Services'));

  const truncateText = (text, maxLength) => {
    const words = text.split(' ');
    if (words.length > maxLength) {
      return words.slice(0, maxLength).join(' ') + '...';
    }
    return text;
  };

  return (
    <div className='main-d-Service'>
      <div className='main-service-heading'> 
        <div className='sub-service-heading'>
          <p className="title-service">Our Services</p>
          <h1 className="title-service1"> <span className='title-service1-color'>Bunch of Services</span> to Rock Your Business</h1>
        </div>
      </div>
      {loading && <Load />}
      {error && <p>Error fetching Services</p>}
      {Services && (
        <div className='service-section-new'>
          {Services.docs.map((Service) => {
            const data = Service.data();
            return (
              <Link key={Service.id} to={`/service/${Service.id}`} className='service-link'>
                <div className='service-section-card'>
                  <img loading="lazy" src={data.iconImageUrl} alt={data.title} className='service-image'/>
                  <div className='service-content'>
                    <h3 className='section-service-title'>{data.title}</h3>
                    <p className='section-service-p'>{truncateText(data.p1, 10)}</p>
                  </div>
                </div>
              </Link>
            );
          })}
        </div>
      )}
    </div>
  );
};
