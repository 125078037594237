import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { signInWithEmailAndPassword, sendPasswordResetEmail } from 'firebase/auth';
import { auth } from '../../firebaseConfig';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { Helmet } from 'react-helmet';
import './Login.css'; // Import your CSS file

const Login = () => {
  const navigate = useNavigate();
  const [values, setValues] = useState({
    email: '',
    pass: '',
  });
  const [resetEmail, setResetEmail] = useState('');
  const [submitButtonDisabled, setSubmitButtonDisabled] = useState(false);
  const [showResetForm, setShowResetForm] = useState(false);
  const [passwordVisible, setPasswordVisible] = useState(false);

  useEffect(() => {
    const currentUser = auth.currentUser;
    if (currentUser) {
      navigate('/service');
    }
  }, [navigate]);

  const handleLogin = () => {
    if (!values.email || !values.pass) {
      toast.error('Fill all fields');
      return;
    }

    setSubmitButtonDisabled(true);

    signInWithEmailAndPassword(auth, values.email, values.pass)
      .then((res) => {
        setSubmitButtonDisabled(false);
        console.log('User signed in successfully:', res.user);

        sessionStorage.setItem('userEmail', values.email);
        sessionStorage.setItem('isLoggedIn', true);

        toast.success('Login successful!');
        navigate(-1);
      })
      .catch((err) => {
        setSubmitButtonDisabled(false);
        toast.error(err.message);
        console.error('Sign-in error:', err);
      });
  };

  const handlePasswordReset = () => {
    if (!resetEmail) {
      toast.error('Please enter your email');
      return;
    }

    sendPasswordResetEmail(auth, resetEmail)
      .then(() => {
        toast.success('Password reset email sent!');
        setShowResetForm(false);
      })
      .catch((err) => {
        toast.error(err.message);
        console.error('Password reset error:', err);
      });
  };

  const handlePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  return (
    <div className="logmain">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Login</title>
        <meta name="description" content="Login page" />
      </Helmet>
      <div className="auth-container">
        <h2>Login</h2>
        {showResetForm ? (
          <>
            <label className="label">Enter your email to reset password:</label>
            <input
              type="email"
              className="input-field"
              value={resetEmail}
              onChange={(e) => setResetEmail(e.target.value)}
            />
            <button className="login-button" onClick={handlePasswordReset}>
              Send Reset Email
            </button>
            <p className="linkk" onClick={() => setShowResetForm(false)}>
              Back to Login
            </p>
          </>
        ) : (
          <>
            <label className="label">Email:</label>
            <input
              type="email"
              className="input-field-email"
              value={values.email}
              onChange={(e) => setValues({ ...values, email: e.target.value })}
            />
            <label className="label">Password:</label>
            <div className="password-input">
              <input
                type={passwordVisible ? "text" : "password"}
                className="input-field"
                value={values.pass}
                onChange={(e) => setValues({ ...values, pass: e.target.value })}
              />
              <button type="button" className="password-toggle" onClick={handlePasswordVisibility}>
                {passwordVisible ? <FaEyeSlash /> : <FaEye />}
              </button>
            </div>
            <button className="login-button" onClick={handleLogin} disabled={submitButtonDisabled}>
              {submitButtonDisabled ? 'Loading...' : 'Login'}
            </button>
            <p className="forgotpass" onClick={() => setShowResetForm(true)}>
              <b>Forgot Password?</b>
            </p>
            <p className="linkk">
              Don't have an account? <Link to="/register">Click here to Register</Link>
            </p>
          </>
        )}
      </div>
      <ToastContainer />
    </div>
  );
};

export default Login;
