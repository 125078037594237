import React from 'react'
import { BiFoodMenu,BiSolidCalendarEdit } from "react-icons/bi";
import { GiTeacher } from "react-icons/gi";
import { useEffect,useState } from 'react';
import "./about.css"
import { FaCheckCircle,FaBookReader,FaChalkboardTeacher } from "react-icons/fa";
import { FaChartLine, FaBuilding, FaComments, FaUserTie } from 'react-icons/fa';
import { HiOutlineComputerDesktop } from "react-icons/hi2";
import { SiGoogleclassroom } from "react-icons/si";
import { SlDislike } from "react-icons/sl";
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
export const Aboutus = () => {
  const [count1, setCount1] = useState(0);
  const [count2, setCount2] = useState(0);
  const [count3, setCount3] = useState(0);
  const [count4, setCount4] = useState(0);
  const [key, setKey] = useState(0); // Key variable to restart the effect
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []); 
  
  return (
    <div className='about-conatiner'>
  
    

      <p className='why-about'>WHY CHOOSE SECONDPUNDIT</p>
      <h1 className='banifit-h1'>The Best <span style={{color:"#F83217"}}>Beneficial </span>Side <br/>of  SECONDPUNDIT</h1>

      <div className="why-us-container">
  
  <div className="card-container">
    <div style={{width:"350px"}} className="card card-experience">
      <i className='aboutus-icon-container1'><FaChartLine className='aboutus-icon' /></i>
      <div className="card-content">
        <h4>Business Growth Planner</h4>
        <p>We are committed to delivering comprehensive services that support business growth and development.</p>
      </div>
    </div>
    <div style={{width:"350px"}} className="card card-services">
      <i className='aboutus-icon-container1'><FaBuilding className='aboutus-icon2' /></i>
      <div className="card-content">
        <h4>New Startup Building</h4>
        <p>We specialize in supporting the establishment and growth of startups through expert planning and development services.</p>
      </div>
    </div>
    <div style={{width:"350px"}} className="card card-support">
      <i className='aboutus-icon-container1'><FaComments className='aboutus-icon3' /></i>
      <div className="card-content">
        <h4>Communication Consulting</h4>
        <p>Our consulting services optimize communication strategies, foster engagement, and enhance messaging effectiveness for businesses and individuals.</p>
      </div>
    </div>
  </div>

</div>

    
<span style={{marginTop:"100px" , display:"flex" ,fontSize:"10PX" ,textAlign:"center" ,alignContent:"center" ,paddingLeft:"40px"}} className='desginedby'> <p1>  PARENT COMPANY GENTLEFOLK CONSULTING PVT LTD <Link
  to="https://www.anantainfotech.com"
  target="_blank"
  rel="noopener noreferrer"
><b>.</b> </Link> </p1>  </span>
    </div>


    



   
  )
}
