import React, { useState, useEffect, useRef } from 'react';
import { collection, addDoc, onSnapshot, query, doc, where, getDoc } from 'firebase/firestore';
import { storage, textdb, auth } from '../../firebaseConfig';
import { getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage';
import { toast } from 'react-toastify';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import './CuChat.css';
import { FaPaperPlane, FaDownload, FaPlus, FaArrowLeft, FaArrowRight, FaTimes } from 'react-icons/fa';

const CuChat = () => {
    const [message, setMessage] = useState('');
    const [adminMessage, setAdminMessage] = useState('');
    const [messages, setMessages] = useState([]);
    const [userName, setUserName] = useState('');
    const [userRole, setUserRole] = useState('');
    const [serviceTitleSent, setServiceTitleSent] = useState(false);
    const [file, setFile] = useState(null);
    const [fileURL, setFileURL] = useState('');
    const [previewURL, setPreviewURL] = useState('');
    const [loading, setLoading] = useState(false);
    const currentUser = auth.currentUser;
    const navigate = useNavigate();
    const { userId } = useParams();
    const location = useLocation();
    const messagesEndRef = useRef(null);
    const [pdfTitle, setPdfTitle] = useState('');
    const [modalOpen, setModalOpen] = useState(false);
    const [currentImage, setCurrentImage] = useState('');
    const [currentIndex, setCurrentIndex] = useState(0);

    const openModal = (imageURL, index) => {
        setCurrentImage(imageURL);
        setCurrentIndex(index);
        setModalOpen(true);
    };

    const closeModal = () => {
        setModalOpen(false);
    };

    const handleNextImage = () => {
        const nextIndex = (currentIndex + 1) % messages.length;
        setCurrentImage(messages[nextIndex].fileURL);
        setCurrentIndex(nextIndex);
    };

    const handlePrevImage = () => {
        const prevIndex = (currentIndex - 1 + messages.length) % messages.length;
        setCurrentImage(messages[prevIndex].fileURL);
        setCurrentIndex(prevIndex);
    };

    const getServiceTitleFromQuery = () => {
        const searchParams = new URLSearchParams(location.search);
        const title = searchParams.get('title');
        return typeof title === 'string' ? decodeURIComponent(title) : '';
    };

    const serviceTitle = getServiceTitleFromQuery();

    const getUserRole = async (user) => {
        if (user) {
            try {
                const userDocRef = doc(collection(textdb, 'users'), user.uid);
                const userDocSnapshot = await getDoc(userDocRef);

                if (userDocSnapshot.exists()) {
                    const userData = userDocSnapshot.data();
                    return userData.role || 'user';
                }
            } catch (error) {
                console.error('Error fetching user data from Firestore:', error);
            }
        }

        return 'user';
    };

    useEffect(() => {
        const fetchData = async () => {
            if (currentUser) {
                const userRole = await getUserRole(currentUser);
                setUserName(currentUser.displayName || '');
                setUserRole(userRole);
            }
        };

        fetchData();
    }, [currentUser]);

    useEffect(() => {
        const fetchData = async () => {
            if (userId) {
                const chatRef = collection(textdb, 'chat2');
                const q = query(
                    chatRef,
                    where('userId', '==', userId)
                );

                const unsubscribe = onSnapshot(q, (querySnapshot) => {
                    const messagesData = [];
                    querySnapshot.forEach((doc) => {
                        messagesData.push({ id: doc.id, ...doc.data() });
                    });

                    messagesData.sort((a, b) => a.timestamp - b.timestamp);

                    setMessages(messagesData);
                });

                return () => unsubscribe();
            }
        };

        fetchData();
    }, [userId]);

    useEffect(() => {
        scrollToBottom();
    }, [messages]);

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    };

    const clearFileInput = () => {
        setFile(null);
        setPreviewURL('');
        setPdfTitle('');
    };

    const handleFileUpload = (e) => {
        const selectedFile = e.target.files[0];

        // Check file size
        if (selectedFile.size > 10 * 1024 * 1024) { // 10 MB limit
            toast.error('File size exceeds 10MB. Please upload a smaller file.');
            return;
        }

        setFile(selectedFile);

        if (selectedFile) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setPreviewURL(reader.result);
            };
            reader.readAsDataURL(selectedFile);

            // Set PDF title for preview
            if (selectedFile.type === 'application/pdf') {
                setPdfTitle(selectedFile.name);
            } else {
                setPdfTitle('');
            }
        } else {
            setPreviewURL('');
            setPdfTitle('');
        }
    };

    const sendMessage = async () => {
        if (!currentUser) {
            toast.error('Unauthorized access. Please log in.');
            return;
        }

        setLoading(true);

        try {
            const chatRef = collection(textdb, 'chat2');
            const timestamp = new Date();

            let finalMessage = message;

            if (serviceTitle && !serviceTitleSent) {
                finalMessage = `${serviceTitle}: ${message}`;
                setServiceTitleSent(true);
            }

            if (file) {
                const storageRef = ref(storage, `files/${file.name}`);
                const uploadTask = uploadBytesResumable(storageRef, file);

                uploadTask.on('state_changed',
                    (snapshot) => {
                        // Handle progress if needed
                    },
                    (error) => {
                        console.error('Error uploading file:', error);
                        toast.error('Error uploading file');
                        setLoading(false);
                    },
                    () => {
                        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                            setFileURL(downloadURL);
                            sendMessageToFirestore(chatRef, finalMessage, timestamp, downloadURL);
                            setLoading(false);
                            clearFileInput();
                        });
                    }
                );
            } else {
                sendMessageToFirestore(chatRef, finalMessage, timestamp);
                setLoading(false);
            }

            setMessage('');
            setAdminMessage('');
        } catch (error) {
            console.error('Error sending message:', error);
            toast.error('Error sending message');
            setLoading(false);
        }
    };

    const sendMessageToFirestore = async (chatRef, message, timestamp, fileURL = '') => {
        if (userRole === 'admin') {
            await addDoc(chatRef, {
                userId,
                message: adminMessage,
                userName: 'Admin',
                timestamp,
                fileURL,
            });
        } else {
            await addDoc(chatRef, {
                userId: currentUser.uid,
                message,
                userName,
                timestamp,
                fileURL,
            });
        }
    };

    const renderMessagesWithDate = () => {
        let currentDate = null;
        return messages.map((msg, index) => {
            const messageDate = new Date(msg.timestamp.seconds * 1000).toLocaleDateString('en-GB', { // DD/MM/YYYY format
                day: '2-digit',
                month: '2-digit',
                year: 'numeric'
            });
            const previousMessageDate = index > 0 ? new Date(messages[index - 1].timestamp.seconds * 1000).toLocaleDateString('en-GB', {
                day: '2-digit',
                month: '2-digit',
                year: 'numeric'
            }) : null;

            const showDate = messageDate !== previousMessageDate && messageDate !== currentDate;
            currentDate = messageDate;

            const dateComponent = showDate ? <div className="message-date">{messageDate}</div> : null;

            return (
                <React.Fragment key={msg.id}>
                    {dateComponent}
                    <div
                        className="message"
                        style={{
                            alignSelf: userRole === 'admin' ? (msg.userName === 'Admin' ? 'flex-end' : 'flex-start') : (msg.userName === 'Admin' ? 'flex-start' : 'flex-end'),
                            backgroundColor: userRole === 'admin' ? (msg.userName === 'Admin' ? '#d1eaf4' : '#f3f3f3') : (msg.userName === 'Admin' ? '#f3f3f3' : '#d1eaf4')
                        }}
                    >
                        <p>
                            <strong>{msg.userName}</strong>: {msg.message} <br />
                            <span className="timestamp">{new Date(msg.timestamp.seconds * 1000).toLocaleTimeString()}</span>
                        </p>
                        {msg.fileURL && (
                            <div className="message-file">
                                {msg.fileURL.endsWith('.pdf') ? (
                                    <div className="pdf-container">
                                        <embed src={msg.fileURL} type="application/pdf" width="100%" height="500px" />
                                        <button className="download-btn" onClick={() => handleDownload(msg.fileURL)}>
                                            <FaDownload /> Download PDF
                                        </button>
                                    </div>
                                ) : msg.fileURL.match(/\.(jpeg|jpg|gif|png)$/) ? (
                                    <div className="image-container">
                                        <img src={msg.fileURL} alt="Uploaded File" className="message-image" />
                                        <button className="download-btn" onClick={() => handleDownload(msg.fileURL)}>
                                            <FaDownload /> Download Image
                                        </button>
                                    </div>
                                ) : (
                                    <div className="file-container">
                                        <img className='mesgimg' src={msg.fileURL} alt='image' onClick={() => openModal(msg.fileURL, index)} />
                                        <a href={msg.fileURL} download className="download-btn">
                                            <FaDownload /> Download File
                                        </a>
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                </React.Fragment>
            );
        });
    };

    const handleDownload = async (fileURL) => {
        try {
            const storageRef = ref(storage, fileURL);
            const downloadURL = await getDownloadURL(storageRef);
            const link = document.createElement('a');
            link.href = downloadURL;
            link.download = fileURL.split('/').pop();
            link.target = '_blank';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } catch (error) {
            console.error('Error fetching download URL:', error);
            toast.error('Error fetching download URL');
        }
    };

    return (
        <div className="chat-container">
            <div className="messages-container">
                <h3>Welcome, {userName} ({userRole})</h3>
                {renderMessagesWithDate()}
                <div ref={messagesEndRef} />
            </div>
            {serviceTitle && !serviceTitleSent && (
                <div className="service-title">
                    <p><strong></strong> {serviceTitle}</p>
                </div>
            )}
           {modalOpen && currentImage && (
    <div className="modal-overlay" onClick={closeModal}>
        <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <div className="modal-header">
                <button title='Download Now' className="download-btn-modal" onClick={() => handleDownload(currentImage)}><FaDownload /> </button>
                <button className="close-btn" onClick={closeModal}><FaTimes /></button>
            </div>
            <div className="modal-body">
                <img
                    src={currentImage}
                    alt="Modal"
                    className='modalimg'
                />
            </div>
            <div className="modal-footer">
                <button className="prev-btn" onClick={handlePrevImage}><FaArrowLeft /></button>
                <button className="next-btn" onClick={handleNextImage}><FaArrowRight /></button>
            </div>
        </div>
    </div>
)}

            {previewURL && (
                <div className="file-preview">
                    {previewURL.endsWith('.pdf') ? (
                        <div>
                            <embed src={previewURL} type="application/pdf" width="100%" height="300px" />
                            <p className="pdf-title">{pdfTitle}</p>
                        </div>
                    ) : (
                        <img src={previewURL} alt="Preview" className="image-preview" />
                    )}
                    <button className="clear-btn" onClick={clearFileInput}>Clear</button>
                </div>
            )}
            <div className='main-input'>

                <div className="input-container">
                    <textarea
                        className='inputtext'
                        style={{ resize: 'none', overflowX: 'hidden' }}
                        value={userRole === 'admin' ? adminMessage : message}
                        onChange={(e) => userRole === 'admin' ? setAdminMessage(e.target.value) : setMessage(e.target.value)}
                        rows={1}
                        onInput={(e) => { e.target.style.height = ''; e.target.style.height = (e.target.scrollHeight) + 'px'; }}
                    />
                    <div className="file-upload">
                        <label htmlFor="fileInput" className="file-label">
                            <FaPlus className="plus-icon" />

                        </label>
                        <input
                            id="fileInput"
                            type="file"
                            onChange={handleFileUpload}
                            className="file-input"
                            style={{ display: 'none' }}
                        />
                    </div>
                    <button className='inputbtn' onClick={sendMessage} disabled={loading}>
                        {loading ? '...' : <FaPaperPlane />}
                    </button>
                </div>
            </div>

        </div>
    );
};

export default CuChat;
