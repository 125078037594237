import React, { useState, useEffect } from 'react';
import "../Hero/Hero.css"
import { Link } from 'react-router-dom';

const Hero = () => {
    const [textIndex, setTextIndex] = useState(0);
    const [showText, setShowText] = useState(true);

    const paragraphs = [
      <span>
          <strong>Empowering Your Business Growth <br/></strong> Welcome to SecondPundit, where expertise meets collaboration to drive your business forward. We specialize in providing tailored consulting solutions to SMEs, offering a comprehensive range of services to support your journey towards success.
      </span>,
      <span>
          <strong>Unlock Your Potential <br/></strong> Our team of professionals is dedicated to understanding your unique needs and challenges. Through strategic guidance, innovative solutions, and personalized support, we empower you to unlock your full potential and achieve your business goals.


      </span>,
      <span>
          <strong>Comprehensive Consulting Solutions <br/>

</strong> From financial and legal advisory to sales management, marketing strategies, IT developments, and printing solutions, we offer a holistic approach to consultancy. With SecondPundit, you have access to a diverse range of expertise all under one roof.

      </span>,
      <span>
      <strong>Collaborative Partnership
 <br/>

</strong> At SecondPundit, we believe in the power of collaboration. We work hand in hand with you, forming a strong partnership built on trust, transparency, and mutual success. Together, we navigate the complexities of business and turn challenges into opportunities.



  </span>,
  <span>
  <strong>Experience Excellence
 <br/>

</strong> With years of experience and a passion for innovation, SecondPundit Consultancy Alliance is your trusted partner in business growth. Let us guide you towards a brighter future, where your success knows no bounds.

</span>,
     
  ];
  

    useEffect(() => {
        const intervalId = setInterval(() => {
            setShowText(false); // Hide text
            setTimeout(() => {
                setTextIndex(prevIndex => (prevIndex + 1) % paragraphs.length);
                setShowText(true); // Show next text
            }, 2000); // Change to the next paragraph after 2 seconds
        }, 3000); // Display each paragraph for approximately 30 seconds

        return () => clearInterval(intervalId);
    }, []);

    return (
        <div className='hero'>
            <>
                <div data-aos="fade-right"
                    data-aos-offset="300"
                    data-aos-easing="ease-in-sine" className='herodata'>
                    <h1>Hire the <br/>
                        TOP 11% of <br/> <span className='hero-h1-span'> Professional </span> Talents</h1>
                  <div className='changing-text'> <p className={showText ? 'fade-in-text' : ''}>
                        {paragraphs[textIndex]}
                    </p></div> 
                    <div className='upper-get-start'>
                    <Link  to='/service' style={{ textDecoration: 'none' }}>
                        <button className='get-start'>Get Started</button>
                    </Link>
                    </div>
                </div>

                <div className='heroimg'>
                <img src='https://res.cloudinary.com/ddkyeuhk8/image/upload/v1720030111/1c5ffbd2-8efa-4463-9b38-1ac858086214.png' alt='Hero Image' className='floating-image' />
            </div>
            </>
        </div>
    );
};

export default Hero;
