import React from 'react';
import "./Step.css"
const Step = () => {
  return (
    <div className="container">
     
      
      <div className="steps">
        <p1 className="title-steps1" >STEPS</p1>
      <p className="title-steps">Easy To <span className='title-step-color'> Get <br/>Started </span></p>
        <div className="step">
          <span className="step-number">01 </span> 
          <div className='main-steps-text'>
          <span className="step-heading">Register & Consult with an Industry Specialist
          </span>
          <p className="step-text">
Our industry experts will work closely with you to understand your unique goals, challenges, and team dynamics. Through a personalized consultation, we'll help you define your project requirements and identify the skills needed to drive success. Our experts will offer valuable insights and guidance to ensure you're well-prepared for your project.
</p>
        </div>
        </div>
        <div className="step">
          <span className="step-number">02 </span> 
          <div className='main-steps-text'>
          <span className="step-heading">Get Matched with Top Professionals
          </span>
          <p className="step-text">We'll connect you with top professionals who possess the skills and expertise you need to achieve your project goals. Our rigorous screening process ensures that only the best candidates are selected, so you can trust that you're getting the highest quality support. Our top professionals are ready to hit the ground running, providing you with the expertise you need to drive results.
          </p>
        </div>
        </div>
        <br/>
        <div className="step">
          <span className="step-number">03 </span> 
          <div className='main-steps-text'>
          <span className="step-heading">Guaranteed Fit for Your Project
          </span>
          <p className="step-text">
          We're committed to delivering the right professional for your project. Our team will work closely with you to ensure a seamless collaboration, guaranteeing a perfect fit. With our expertise and guidance, you can trust that your project is in good hands. We'll work tirelessly to ensure your success, providing you with a hassle-free experience that exceeds your expectations.</p>
        </div>
        </div>
      </div>
      <div className='StepImage'> 
        
        <img src='https://res.cloudinary.com/ddkyeuhk8/image/upload/v1720582216/ktcaybrnntwbrwzyktus.png ' alt='/' />
        </div>
    </div>
  );
};

export default Step;
